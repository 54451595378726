<template>
    <div class="wrap" v-if="game">
        <div class="caidai"></div>
        <div class="header clearfix">
            <p class="rule fl" @click="showRule = true">规则</p>
            <p class="close-game fr" @click="close">关闭</p>

        </div>
        <!--轮盘-->
        <div class="rotate">
            <div class="lunpai">
                <ul class="prize running">
                    <li v-for="(item,index) in game.prize" :key="index"
                        :style="{backgroundImage:'url('+background(item)+')'}">
                        <span></span>
                        <p>{{item.remark}}</p>
                    </li>
                </ul>
            </div>
            <div class="ring"></div>
            <div class="draw_btn" @click="draw"></div>
        </div>
        <div class="border">
            余额 <span>{{gold}}</span>,单次抽奖:{{game.price}}
        </div>
        <audio id="audio">
            <source src="https://storage.wnwdkj.cn/turnbg.wav" type="audio/wav">
        </audio>
        <rule :content="game['remark']" v-show="showRule" @close="showRule = false"/>
        <prize v-if="showPrize" :prize="prize" @close="reset"/>
    </div>
</template>

<script>
    import prize from "@/components/prize";
    import rule from "@/components/rule";
    import '@/assets/turn/css/index.css'
    import losing from '@/assets/turn/image/face.png'
    import gold from '@/assets/gold.png'
    import '@/assets/jquery.rotate'
    import {Toast} from "vant";

    export default {
        name: "index",
        components: {
            prize,
            rule
        },
        data() {
            return {
                showRule: false,
                showPrize: false,
                prize: null,
                game: null,
                gold: 0,
                code: 'turn',
                losing: losing,
                inDraw: false,
            }
        },
        created() {
            this.$http.get('lottery-game/info', {params: {code: this.code}}).then((res) => {
                this.game = res.data;
                this.gold = res.data['gold']
                this.$nextTick(() => {
                    this.initJquery()
                })
            })
        },
        methods: {
            background(item) {
                let type = parseInt(item.type)
                if (type === 2) {
                    return losing
                } else if (type === 0) {
                    return gold
                } else {
                    return item.image
                }
            },
            reset() {
                this.showPrize = false;
                let $prize = $('.prize')
                $prize.addClass("running");
            },
            initJquery() {
                $(function () {
                    setInterval(function () {
                        $(".ring").toggleClass("light");
                    }, 1000);
                });
            },
            draw() {
                if (this.inDraw) {
                    return
                }
                if (parseInt(this.gold) < parseInt(this.game.price)) {
                    return Toast.fail({message: '余额不足', position: 'bottom'})
                }

                $("#audio").get(0).play()
                this.inDraw = true;
                var that = this
                let $prize = $('.prize')
                $prize.removeClass("running");
                that.$http.post('lottery-game/draw', {code: that.code}).then((res) => {
                    that.prize = res.data
                    that.gold = res.data['gold']
                    let index = parseInt(res.data['index'])
                    switch (index) {//中奖概率，可控。根据得到的随机数控制奖品
                        case 0:
                            rotateFn(0);
                            break;
                        case 1:
                            rotateFn(-60);
                            break;
                        case 2:
                            rotateFn(-120);
                            break;
                        case 3:
                            rotateFn(-180);
                            break;
                        case 4:
                            rotateFn(-240);
                            break;
                        case 5:
                            rotateFn(-300);
                            break;
                    }
                });

                //选中函数。参数：奖品序号、角度、提示文字
                function rotateFn(angle) {
                    $prize.stopRotate();
                    $prize.rotate({
                        angle: 0,//旋转的角度数
                        duration: 4000, //旋转时间
                        animateTo: angle + 1800, //给定的角度,让它根据得出来的结果加上1440度旋转。也就是至少转4圈
                        callback: function () {
                            that.inDraw = false
                            that.showPrize = true
                        }
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
